import { render, staticRenderFns } from "./auth-change-tip-dig.vue?vue&type=template&id=49a212a2&scoped=true&"
import script from "./auth-change-tip-dig.vue?vue&type=script&lang=js&"
export * from "./auth-change-tip-dig.vue?vue&type=script&lang=js&"
import style0 from "./auth-change-tip-dig.vue?vue&type=style&index=0&id=49a212a2&scoped=true&lang=css&"
import style1 from "./auth-change-tip-dig.vue?vue&type=style&index=1&id=49a212a2&scoped=true&lang=scss&"
import style2 from "./auth-change-tip-dig.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a212a2",
  null
  
)

export default component.exports