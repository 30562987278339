<!--
  西瓜视频组件
-->
<template>
  <div
      ref="wEleVideoParent"
      class="w-ele-video-parent"
      v-loading="loading"
      element-loading-text="视频加载中..."
      :class="['loading-'+loadingBg,!noRelative ? 'relative' : '',]"
  >
    <div :id="'xg-video-'+ videoObj.v_ID"></div>
    <div class="error-div" v-if="error">{{error}}</div>
    <div
        class="img-mask"
        :class="{
          'playing': playing,
        }"
        v-if="!error && (screenshotAuth || downAuth || !playing)"
    >
      <ConIcon
          @click="getScreenshot"
          tipText="截图"
          icon="icon_jietu"
          v-if="screenshotAuth"
      ></ConIcon>
      <ConIcon
          v-if="downAuth"
          class="down"
          tipText="下载"
          @click="$emit('emitDownloadCodeImg')"
          icon="icon_download"
      ></ConIcon>
      <ConIcon
          v-if="!playing"
          class="fullScreen"
          tipText="全屏"
          @click="fullScreen"
          icon="icon_quanpin"
      ></ConIcon>
    </div>
  </div>
</template>
<script>
/**
 * 本店视频  获取视频，以及解析视频清晰度
 */
import videoClarity from "@/mixins/video/videoClarity";
import Player from 'xgplayer/dist/simple_player';
import volume from 'xgplayer/dist/controls/volume'; // 音量
import definition from 'xgplayer/dist/controls/definition'; // 清晰度切换
import screenShot from 'xgplayer/dist/controls/screenShot'; // 截图
import playbackRate from 'xgplayer/dist/controls/playbackRate'; // 倍速播放
import enter from 'xgplayer/dist/controls/enter'; // 视频起播加载UI控件
import error from 'xgplayer/dist/controls/error'; // 播放出错
export default {
  name: "video-element",
  mixins: [
    videoClarity
  ],
  data() {
    return {
      playing: false, // 播放中
      screenshotAuth: false,
      downAuth: false,
      error: '',
      loading: true,
      player: null,
      videoType: 'avi,rmvb,rm,asf,divx,mpg,mpeg,mpe,wmv,mp4,mkv,vob,mov,m4v,m2t,f4v,m2p', // 视频类型常见后缀
    }
  },
  props: {
    videoObj: {
      type: Object,
      required: true,
      default() {
        return {
          v_url: '', // 视频资源。  v_url 与 v_videoList 必传一个
          v_videoList: [], // 直接显示视频资源。  v_url 与 v_videoList 必传一个
          v_ID: '', // 唯一ID  必传
          ID: '', // 必传

          v_originalUrl: '', // 原视频资源 ，只有需要获取清晰度v_isTXY=true的时候才必须
          v_isTXY: false, // 是否处理视频清晰度

          v_screenshot: false, // 是否截图
          v_download: false, // 是否可下载
          v_cover: '', // 封面图
          autoplay: false, // 是否自动播放
        }
      }
    },
    /**
     * 是否是案例
     */
    isCase: {
      type: Boolean,
      default: false
    },
    /**
     * loading背景
     */
    loadingBg: {
      type: String,
      default: 'white', // white black
    },
    /**
     * 视频跨域问题 给视频加时间戳
     */
    urlCross: {
      type: Boolean,
      default: false
    },
    /**
     * 当前组件没有 相对定位 ，相对定位在外层
     */
    noRelative: {
      type: Boolean,
      default: false
    },
    fluid: { // 流式布局
      type: Boolean,
      default: false
    },
  },
  watch: {
    isChange: {
      handler() {
        this.$nextTick(() => {
          this.initVideoUrl();
        })
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    isChange() {
      let val = '';
      if (
          this.videoObj.v_videoList &&
          this.videoObj.v_videoList.length > 0
      ) {
        val = this.videoObj.v_videoList;
      }

      if (this.videoObj.v_url) {
        val = this.videoObj.v_ID + this.videoObj.v_url;
      }
      return val;
    }
  },
  methods: {
    /**
     * 初始化视频资源
     */
    initVideoUrl() {
      const caseAuth = this.$store.state.authButton && this.$store.state.authButton.hasOwnProperty('case') ? this.$store.state.authButton['case'] : [];
      const editAuthCode = 'Edit_CASE';
      const downAuthCode = 'Case_Down';
      this.screenshotAuth = false;
      this.downAuth = false;
      // 案例
      if (
          this.isCase &&
          caseAuth
      ) {
        this.screenshotAuth = this.videoObj.v_screenshot && !!caseAuth.find(ua => ua.EnCode.toLowerCase() === editAuthCode.toLowerCase());
        this.downAuth = !!caseAuth.find(ua => ua.EnCode.toLowerCase() === downAuthCode.toLowerCase());
      }
      // 非案例
      if (!this.isCase) {
        this.screenshotAuth = !!this.videoObj.v_screenshot;
        this.downAuth = !!this.videoObj.v_download;
      }

      const {
        v_url = '', // 视频资源
        v_originalUrl = '', // 原视频
        v_videoList = [], // 处理过的视频资源
        v_isTXY = true, //  是否处理视频清晰度
      } = this.videoObj;

      this.loading = true;
      this.player = null;
      this.error = '';

      console.log(v_url,v_videoList,'加载视频....');

      // 本地视频 直接显示
      if (
          v_url.indexOf('blob:') !== -1
      ) {
        this.error = '';
        const list = [{key: 'original', 'url': v_url, 'name': '原画'}]
        this.initPlay(list);
        return;
      }

      /**
       * 视频资源直接显示
       */
      if (
          v_videoList &&
          v_videoList.length > 0
      ) {
        if (this.videoType.indexOf(this.getSuffix(v_videoList[0].url).toLowerCase()) !== -1) {
          this.error = '';
          this.initPlay(v_videoList);
        } else {
          this.error = '视频资源错误';
          this.loading = false;
        }
        return
      }

      // 内网获取资源
      if (
          v_url.indexOf('https://localhost') !== -1 ||
          v_url.indexOf('http://localhost') !== -1
      ) {
        let list = [{'key': 'original', 'url': `${v_url}`, 'name': '原画'}];
        this.initPlay(list);
        return;
      }

      // 视频资源需要 获取清晰度
      if (
          this.videoType.indexOf(this.getSuffix(v_url).toLowerCase()) !== -1
      ) {
        Promise.all([
          this.getHeadvideo(v_url), // 原画视频
          this.getOtherVideoUrl(v_isTXY,v_originalUrl,v_url) // 处理不同清晰度
        ]).then(res => {
          let list = [];
          let val0 = [];
          let res0 = res[0];
          let res1 = res[1];
          if (res0) val0 = [{'key': 'original', 'url': `${v_url}`, 'name': '原画'}];
          list = [...res1,...val0];
          this.initPlay(list);
        }).catch(() => {
          this.error = '视频资源错误';
          this.loading = false;
        })
      } else {
        this.error = '视频资源错误';
        this.loading = false;
      }
    },
    /**
     * 初始化视频资源
     * @param list
     */
    initPlay(list) {
      /**
       * 存在缓存跨域视频 添加版本号
       */
      if (
          this.urlCross
      ) {
        list.forEach(c => {
          if (
              c.url.indexOf('https://localhost') === -1 &&
              c.url.indexOf('http://localhost') === -1
          ) {
            c.url = `${c.url}?v=${new Date().getTime()}`
          }
        });
      }

      this.player = new Player({
        id: 'xg-video-'+ this.videoObj.v_ID,
        url: list.length > 0 ? list[0].url : 'error',
        definitionActive: 'click', // 切换清晰度 视频只有一个地址 默认关闭切换清晰度控件
        poster: this.videoObj.v_cover,
        width: '100%',
        height: '100%',
        errorTips: `请<span class="xgplayer-error-refresh">刷新</span>试试`,
        controlPlugins: [
          volume,
          definition,
          screenShot,
          playbackRate,
          enter,
          error
        ],
        screenShot: {
          saveImg: false,
        },
        autoplay: this.videoObj.autoplay,
        lang: 'zh-cn',
        playbackRate: [0.5, 0.75, 1, 1.5, 2],
        defaultPlaybackRate: 1,
        videoInit: !this.videoObj.autoplay && !this.videoObj.v_cover, //没有封面时 视频第一帧作为封面
      })

      // 清晰度配置
      if (
          list.length > 0 &&
          list[0].key !== 'original'
      ) {
        this.player.emit('resourceReady', list);
      }

      this.loading = false;

      // 准备完成
      this.player.once('ready',()=>{
        if (!this.player.controls || !this.player.video) return;
        this.posterClick();
      })

      // 监听 loadedmetadata 事件
      this.player.on('loadedmetadata', () => {
        this.scaleElement();
      });

      // 监听截图
      this.player.on('screenShot', (img) => {
        img = img.replace('data:application/octet-stream', 'data:image/png');
        this.$emit('getScreenShot', img)
      })

      // 监听播放
      this.player.on('play', () => {
        this.playing = true;
        this.$bus.$emit('xgVideoPlay', this.videoObj.ID);
      })

      // 监听鼠标移入 视频是否播放中
      this.player.on('focus', () => {
        this.playing = false;
        if (
            this.player &&
            this.player.controls &&
            this.player.controls.parentElement
        ) {
          const parentElement = this.player.controls.parentElement;
          const className = parentElement.getAttribute('class'); // 'xgplayer-playing'
          this.playing = className.indexOf('xgplayer-playing') !== -1;
        }
        this.$forceUpdate();
      })
    },
    scaleElement() {
      if (!this.player) return;
      const video = this.player.video;
      const containerWidth = this.$refs.wEleVideoParent && this.$refs.wEleVideoParent.clientWidth;
      const containerHeight = this.$refs.wEleVideoParent && this.$refs.wEleVideoParent.clientHeight;
      const elementOriginalWidth = video.videoWidth;
      const elementOriginalHeight = video.videoHeight;
      // 计算容器和元素的宽高比
      const containerAspectRatio = containerWidth / containerHeight;
      const elementAspectRatio = elementOriginalWidth / elementOriginalHeight;

      if (!containerWidth || !containerHeight || !elementOriginalWidth || !elementOriginalHeight) return;

      if (!this.fluid) return;
      let newWidth, newHeight;
      if (containerAspectRatio > elementAspectRatio) {
        // 容器更宽，按高度缩放
        newHeight = containerHeight;
        newWidth = newHeight * elementAspectRatio;
      } else {
        // 容器更高，按宽度缩放
        newWidth = containerWidth;
        newHeight = newWidth / elementAspectRatio;
      }

      // 设置元素的新宽度和高度
      video.style.width = newWidth + 'px';
      video.style.height = newHeight + 'px';
    },
    /**
     * 点击封面图 播放视频
     * @param isDestroy 是否是注销
     */
    posterClick(isDestroy) {
      if (
          this.player &&
          this.player.controls &&
          this.player.controls.parentElement &&
          this.player.controls.parentElement.getElementsByClassName('xgplayer-poster') &&
          this.player.controls.parentElement.getElementsByClassName('xgplayer-poster')[0]
      ) {
        const posterElement = this.player.controls.parentElement.getElementsByClassName('xgplayer-poster')[0];
        if (!isDestroy) {
          posterElement.addEventListener('click', () => {
            if (this.player.userGestureTrigEvent) {
              this.player.userGestureTrigEvent('startBtnClick');
            }
          })
        } else {
          posterElement.removeEventListener('click', () => {
            if (this.player.userGestureTrigEvent) {
              this.player.userGestureTrigEvent('startBtnClick');
            }
          })
        }
      }
    },
    // 处理不同清晰度
    async getOtherVideoUrl(v_isTXY,v_originalUrl,v_url) {
      let res = [];
      if (v_isTXY) res = await this.getVideoUrlDetail(v_originalUrl ? v_originalUrl : v_url);
      return res;
    },
    // 获取文件后缀名
    getSuffix(filename) {
      return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    },
    getScreenshot() {
      // 视频处于播放中的情况 ||  没有封面时 视频第一帧作为封面的情况
      if (
          this.player &&
          (
              (
                  !this.videoObj.autoplay &&
                  !this.videoObj.v_cover
              ) ||
              (
                  this.player.played &&
                  this.player.played.length > 0
              )
          )
      ) {
        this.player.emit('screenShotBtnClick');
      } else {
        // 非播放中 截图 原封面
        this.$emit('getScreenShot', this.videoObj.v_cover);
      }
    },
    /**
     * 进入全屏并播放
     */
    fullScreen() {
      if (
          this.player &&
          this.player.userGestureTrigEvent
      ) {
        this.player.userGestureTrigEvent('startBtnClick');
        this.player.emit('fullscreenBtnClick');
      }
    },
    videoPlayStatus(ID) {
      if (ID !== this.videoObj.ID && this.player) {
        this.player.pause();
      }
    },
  },
  mounted() {
    this.$bus.$on('xgVideoPlay', this.videoPlayStatus);
    this.$bus.$on('resize', this.scaleElement);

  },
  beforeDestroy() {
    if (this.player) {
      let isDestroy = true;
      this.posterClick(isDestroy); // 注销监听封面点击事件

      this.player.destroy(true);
    }
    this.$bus.$off('xgVideoPlay', this.videoPlayStatus);
    this.$bus.$off('resize', this.scaleElement);
  }
}
</script>
<style>
body .w-ele-video-parent .xgplayer-skin-default .xgplayer-screenshot {
  display: none;
}
body .w-ele-video-parent .xgplayer-skin-default {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .w-ele-video-parent .xgplayer-skin-default .xgplayer-start div svg {
  fill: #000;
  background: rgba(255,255,255,0.9);
}
body .w-ele-video-parent .xgplayer-skin-default .xgplayer-definition .name {
  left: 0;
}
body .w-ele-video-parent .xgplayer-skin-default.xgplayer-definition-active,
body .w-ele-video-parent .xgplayer-skin-default.xgplayer-playbackrate-active {
  z-index: auto;
}
body .w-ele-video-parent .xgplayer-skin-default .xgplayer-playbackrate {
  margin-left: 10px;
}
body .w-ele-video-parent .xgplayer-skin-default .xgplayer-enter .xgplayer-enter-spinner {
  width: 60px;
  height: 60px;
}
body .w-ele-video-parent .xgplayer.xgplayer-ended.xgplayer-is-fullscreen .xgplayer-controls,
body .w-ele-video-parent .xgplayer.xgplayer-is-error.xgplayer-is-fullscreen .xgplayer-controls {
  display: flex;
  justify-content: flex-end;
  z-index: 200;
}
body .w-ele-video-parent .xgplayer.xgplayer-ended.xgplayer-is-fullscreen .xgplayer-controls > *,
body .w-ele-video-parent .xgplayer.xgplayer-is-error.xgplayer-is-fullscreen .xgplayer-controls > * {
  display: none;
}
body .w-ele-video-parent .xgplayer.xgplayer-ended.xgplayer-is-fullscreen .xgplayer-controls .xgplayer-fullscreen,
body .w-ele-video-parent .xgplayer.xgplayer-is-error.xgplayer-is-fullscreen .xgplayer-controls .xgplayer-fullscreen {
  display: block;
}
</style>
<style scoped lang="scss">
.w-ele-video-parent {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover .img-mask {
    opacity: 1;
  }
}
.w-ele-video-parent.relative {
  position: relative;
}
.error-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #999;
}
.w-ele-video-parent .img-mask {
  z-index: 2;
  background: none!important;
  position: absolute;
  left: auto!important;
  right: 0!important;
  bottom: 10px!important;
  width: auto!important;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  opacity: 0;
  transition: 0.2s;
  .iconfont {
    margin: 0!important;
    margin-left: 12px!important;
    color: #ffffff;
    background: rgba(0, 0, 0, .5);
    @include themify($themes) {
      border-radius: themed("border-radius-min");
    }
    &:hover {
      color: #ffffff;
      background: rgba(0, 0, 0, .5);
    }
  }
}
.w-ele-video-parent .img-mask.playing {
  bottom: 50px!important;
}

.w-ele-video-parent.loading-black.el-loading-parent--relative ::v-deep .el-loading-mask {
  background: rgba(0,0,0,0.8)!important;
}
.w-ele-video-parent.el-loading-parent--relative ::v-deep .el-loading-mask .el-loading-spinner .path,
.w-ele-video-parent.el-loading-parent--relative ::v-deep .el-loading-mask .el-loading-spinner .el-loading-text {
  stroke: #999;
  color: #999;
}
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-playbackrate .name {
  width: 40PX;
  margin-left: 10px;
}
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-definition .name {
  overflow: hidden;
  width: 40PX;
  margin-left: 10px;
}
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-time {
  margin: 0 4px;
}
.vodeo-box-item:hover .w-ele-video-parent .img-mask {
  opacity: 1!important;
}
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-playbackrate ul,
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-definition ul {
  padding: 10px 0;
}
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-playbackrate li,
.w-ele-video-parent ::v-deep .xgplayer-skin-default .xgplayer-definition li {
  margin-bottom: 20px;
  font-size: 14px;
}
</style>
