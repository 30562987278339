import Axios from 'axios';
import {Message} from 'element-ui';
import Compressor from 'compressorjs'
import version from '../../../../version.json';

Axios.defaults.timeout = 30000; //设置超时时间
const nowVersion = 'plus' + (version && version.Version || '0.1');
let COS = require('cos-js-sdk-v5');
const Custom_Method = {
        /**
         *封装axios
         * @param 传进的method、url、data等对象
         */
        $axios(param) {

            let _that = this

            let Authorization = sessionStorage.getItem('Authorization') //获取请求头
            if (param.authorizationStr) {
                Authorization = param.authorizationStr;
            }
            let ContentType = 'application/json;charset=UTF-8'
            if (param.ContentType) {
                ContentType = param.ContentType;
            }
            if (typeof param != "object") {
                throw 'This is no Object'
            }
            if (param.url.indexOf('/hostapi') < 0 && param.url.indexOf('http://') < 0 && param.url.indexOf('https://') < 0) {
                param.url = process.env.VUE_APP_URL + param.url;
            }
            let method = param.method.toUpperCase(); //获取当前请求方式
            let Urls = param.url + URL(param.data)
            let axiosObj = {
                method: method,
                headers: {
                    Authorization,
                    'Content-Type': ContentType
                },
                url: method == 'GET' ? Urls : param.url,
                data: param.data
            };
            let isTipLogin = sessionStorage.getItem('isTipLogin');
            Axios(axiosObj).then(res => {
                param.success && param.success.call(_that, res);
            }).catch(err => {
                //请求错误统一处理
                if (!err.response) {
                    // 断网了
                    console.log(err)
                    if (err.message == "Network Error") {
                        err.message = '网络连接不可用'
                    } else {
                        err.message = '出现异常,请刷新后重试'
                    }
                } else {
                    switch (err.response.status) {
                        case 400:
                            err.message = '请求错误'
                            break
                        case 401:
                            {
                                isTipLogin = sessionStorage.getItem('isTipLogin');
                                err.message = isTipLogin != 1 ? '登录过期，请重新登录' : '';
                                sessionStorage.setItem('isTipLogin', 1)
                                setTimeout(() => {
                                    window.location = process.env.VUE_APP_PAGEURL;
                                }, 1500);
                                err.message = '未授权，请登录';
                                // this.$router.push({ path: '/' });
                            }
                            break
                        case 403:
                            err.message = '拒绝访问'
                            break
                        case 404:
                            err.message = `请求地址出错`
                            break
                        case 408:
                            err.message = '请求超时'
                            break
                        case 500:
                            err.message = err.response.data.message ? err.response.data.message : '服务器内部错误'
                            break
                        case 501:
                            err.message = '服务未实现'
                            break
                        case 502:
                            err.message = '网关错误'
                            break
                        case 503:
                            err.message = '服务不可用'
                            break
                        case 504:
                            err.message = '网关超时'
                            break
                        case 505:
                            err.message = 'HTTP版本不受支持'
                            break
                        default:
                    }
                }

                if (param.error) {
                    param.error && param.error.call(_that, err);
                }
                Message.closeAll();
                Message({
                    message: err.message,
                    type: 'error'
                })
            });
        },
        //错误提示
        $Error(str) {
            Message.closeAll();
            this.$message({
                message: str,
                iconClass: 'iconfont icon_error',
                customClass: 'err-msg-icon',
                duration: 1500
            });
        },
        $Success(str) {
            Message.closeAll();
            this.$message({
                message: str,
                iconClass: 'iconfont btn_sel',
                customClass: 'suc-msg-icon',
                duration: 1500
            });
        },
        $Message(str, type = "warn",duration = 1500,offset = 20) {
            Message.closeAll();
            let icon = "icon_danger";
            switch (type) {
                case 'suc':
                    icon = 'btn_sel';
                    break;
                case 'err':
                    icon = 'icon_error';
                    break;
                case 'loading':
                    icon = 'el-icon-loading';
                    break;
                default:
                    icon = 'icon_danger';
                    break;
            }
            this.$message({
                message: str,
                iconClass: 'iconfont ' + icon,
                customClass: type + '-msg-icon',
                duration: duration,
                offset: offset
            });
        },
        /**
         * 验证手机号正确性
         * @param {*} phone 手机号
         * @param {*} isNull 是否可以为空
         */
        $verifPhone(phone, isNull = true) {
            // let rega = /^((0\d{2,3}-\d{7,8})|(1([3568][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}))$/;
            let rega = /^1\d{10}$/; //仅验证为11位数字
            if (rega.test(phone)) {
                return true;
            }
            if (!phone || phone.length == 0) {
                return isNull;
            }
            return false;
        },

        //图片转base64
        $ImgBase64(file, callback) {
            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function(reader) {
                callback.call(that, reader.currentTarget.result)
            }

        },
        /**
         * 寻找父级变量 并修改
         * @method ThisParent
         * @for ThisParent
         * @param {Object}
         * @return {callblck} 方法回调
         * */
        $_this(data) {
            let that = this;
            let parent = this.$parent;

            function f(t) {
                let arr = Object.keys(t.$data);
                if (arr.indexOf(data.variable) == -1) {
                    parent = parent.$parent
                    f(parent)
                } else {
                    data.callblack.call(that, parent)
                }
            }
            f(parent)
        },
        //对象克隆
        $Clone(obj) {
            return clone(obj);
        },
        /**
         * 格式化显示日期时间 format:模板(sample "yyyy-MM-dd HH:mm:ss SSS")  date:日期对象
         */
        $DateFormat(date, format = "yyyy-MM-dd HH:mm:ss SSS") {
            if (!date) return "";
            if (typeof date === "string") {
                date = date.replace('T', " ");
                date = date.replace(/-/g, "/");
                date = date.split('.')[0];
            }
            date = new Date(date);
            var o = {
                "M+": date.getMonth() + 1, //月份
                "d+": date.getDate(), //日
                "H+": date.getHours(), //小时
                "m+": date.getMinutes(), //分
                "s+": date.getSeconds(), //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                S: date.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(format))
                format = format.replace(
                    RegExp.$1,
                    (date.getFullYear() + "").substr(4 - RegExp.$1.length)
                );
            for (var k in o)
                if (new RegExp("(" + k + ")").test(format))
                    format = format.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1 ?
                        o[k] :
                        ("00" + o[k]).substr(("" + o[k]).length)
                    );
            return format;
        },
        /**
         * 获取文件本地地址
         */
        $GetRealFileList(pathList, callback, _self) {
            let fileStr = "";
            fileStr = pathList.join(",");
            if (pathList.filter(f => f != "").length == 0) {
                return new Promise(reslove => reslove([]))
            }
            return new Promise((reslove, reject) => {
                let url = "http://localhost:9876/Api/GetRealFileList";
                let issafariBrowser = !/Chrome/.test(navigator.userAgent);
                if (issafariBrowser) {
                    url = "https://localhost:9898/Api/GetRealFileList";
                }
                let isMac = /macintosh|mac os x/i.test(navigator.userAgent.toLocaleLowerCase());

                $.ajax({
                    url: url,
                    type: "POST",
                    datatype: 'json',
                    data: {
                        FileList: fileStr
                    },
                    timeout: isMac ? 2500 : 6000, // 超时时间
                    success(res) {
                        if (res) {
                            if (typeof res === "string") {
                                res = JSON.parse(res);
                            }
                            res.forEach(file => {
                                file.NewShowUrl = file.NewShowUrl.replace('https://192.168.1.18:9898', 'https://localhost:9898')
                            })
                            reslove(res);
                        } else {
                            reject(res);
                            realFileError(callback, _self);
                        }

                    },
                    error(err) {
                        reject(err);
                        realFileError(callback, _self);
                    }
                });
            });
        },
        /**
         * 下载文件
         * @param {*} options 下载文件参数
         */
        $downLoad(options) {
            let newCos = this.$store.newCos;
            downLoad(newCos, options);
        },

        /**
         * 新文件上传 skd上传
         */
        $newUploadFile(options) {
            let newCos = this.$store.newCos;
            //判断存储空间大小
            uploadFun(options, newCos);
            // startUpload(options, newCos);
        },
        /**
         *  清空当前队列
         */
        $emptyQueue(title, msg, position, type = 'success') {
            queueList = [];
            queueIngs = []
        },
        /**
         * 批量上传
         * @param {上传参数} options
         */
        $newUploadFileList(options) {
            let newCos = this.$store.newCos;
            newUploadFileList(newCos, options)
        },
        /**
         * 取消上传/重新上传
         */
        $cancelUpload(taskId, type, callback,options) {
            let newCos = this.$store.newCos;
            cancelUpload(newCos, taskId, type, callback);
            cancelUpload(newCos, taskId, type, callback, options);
        },
        /**
         * 删除多个对象
         * @param {*} fileKeyList 删除对象key集合
         */
        $deleteObject(fileKeyList, callback) {
            let newCos = this.$store.newCos;
            deleteMultipleObject(newCos, fileKeyList, callback)
        },
        /**
         * 生成新的guid
         */
        $newGuid() {
            return newGuid();
        },
        /**
         *  悬浮出现在页面角落，显示全局的通知提醒消息。
         */
        $Notifys(title, msg, position, type = 'success') {
            this.$notify({ title, message: msg, position, type });
        },
        /**
         * 本地文件转为可播放Url
         * @param {*} file 需要转换的文件
         */
        $getObjectURL(file) {
            var url = null;
            if (window.createObjectURL != undefined) {
                // basic
                url = window.createObjectURL(file);
            } else if (window.URL != undefined) {
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL != undefined) {
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        },
        /**
         * 缩略图
         * @param url
         * @returns {*}
         */
        $YsUrl(url) {
            let YsUrl = ''
            if (url) {
                YsUrl = !url.includes('imageMogr2') ? url + (!url.includes('?') ? '?' : '&') + 'imageMogr2/thumbnail/48x' : !url.includes('thumbnail') ? url + '/thumbnail/48x' : url.replace('800', '48');
            } else {
                YsUrl = url;
            }
            return YsUrl;
        },
        $copyInfo(copyValue) { //复制内容
            let domUrl = document.createElement('input');
            domUrl.value = copyValue
            domUrl.id = 'creatDom';
            document.body.appendChild(domUrl);
            domUrl.select(); // 选择对象
            document.execCommand('Copy'); // 执行浏览器复制命令
            let creatDom = document.getElementById('creatDom');
            creatDom.parentNode.removeChild(creatDom);
            this.$Message("复制成功，可贴粘", "success");
        },
        //获取url中的参数
        $urlParamHash() {
            let url = decodeURIComponent(location.href);
            const arr = url.indexOf('#') ? url.split('#') : url;
            let params = {}, h;
            let list = [];
            arr.forEach(c => {
                let hash = c.slice(c.indexOf("?") + 1).split("&");
                list = [...list,...hash];
            })
            list = list.filter(c => c && c.indexOf('=') > -1)
            for (var i = 0; i < list.length; i++) {
                const str = list[i];
                const equalLength = str.match(/=/ig).length; // 等于符号的个数
                if (equalLength > 1) {
                    let len = str.length;
                    const index = str.indexOf('=');
                    name = str.slice(0,index);
                    params[name] = str.substring(index+1,len);
                } else {
                    h = list[i].split("=");
                    params[h[0]] = h[1];
                }
            }
            return params;
        },
        /**
         * 获取路由前缀
         * 便于配置版本号 清浏览页面缓存
         *
         * nameType 获取的哪个项目的地址
         * isFrame 是否是内嵌iframe
         */
        $getUrlPrefix(nameType,isFrame = true) {
            const vName = 'x-web-version';
            const v = sessionStorage.getItem(vName) || localStorage.getItem(vName) || '1.0';
            const version_now = nowVersion + (nowVersion && v ? '_' : '') + v;
            const obj = {
                '1.0': `${process.env.VUE_APP_PAGEURL}`, // 1.0 经典版
                '2.0': `${process.env.VUE_APP_PAGEURL}new2.0/index.html`, // 2.0 深色版/浅色版
                'backstage': `${process.env.VUE_APP_PAGEURL}back/index.html`, // 后台
                'host': `${process.env.VUE_APP_PAGEURL}NewVuehtml/newHost/index.html`, // hostPC
                'Inspiration': `${process.env.VUE_APP_PAGEURL}Function/Inspiration/index.html`, // 灵感
                'Networks' : `${process.env.VUE_APP_PAGEURL}Function/Networks/index.html`, // 网盘
                'hotel': `${process.env.VUE_APP_PAGEURL}hotel/index.html`, // 后台酒店
                'version': isFrame ? version_now : v, // 版本号
            }
            if (!nameType) return obj;
            const vStr = obj.version;
            switch (nameType) {
                case '1.0': return obj['1.0'] + '?v=' + vStr; // 1.0 经典版
                case '2.0': return obj['2.0'] + '?v=' + vStr; // 2.0 深色版/浅色版
                case 'backstage': return obj['backstage'] + '?v=' + vStr; // 后台
                case 'host': return obj['host'] + '?v=' + vStr; // hostPC  项目路由
                case 'Inspiration': return obj["Inspiration"] + '?v=' + vStr; // 灵感
                case 'hotel': return obj['hotel'] + '?v=' + vStr; // 后台酒店
            }
        },
        // 删除 图片webp地址
        $delWebp(url) {
            return url && url.includes('?imageMogr2/format/webp') ? url.split('?imageMogr2/format/webp')[0] : url;
        },
        /**
         * 文件地址 是否是全路径
         * @param url
         * @param typeStr host 小程序路径 \ pc pc路径
         * @returns {*}
         */
        $urlFullPath(url,typeStr) {
            const cosIp = process.env.VUE_APP_IMGURL_MI;
            const imgIp = sessionStorage.getItem('BranchCosCdnUrl');
            const ip = typeStr === 'host' ? cosIp : imgIp;
            return url && !url.includes('http') ? ip + url : url;
        }
    }
    //Get 数据处理
const URL = (dataObj) => {
    let url = ''
    let dataObjArr = []
    if (dataObj == '') {
        return ''
    }
    for (var key in dataObj) {
        if (dataObj.hasOwnProperty(key)) {
            dataObjArr.push(key);
            if (dataObjArr.length == 1) {
                url += '?' + key + '=' + dataObj[key];
            } else {
                url += '&' + key + '=' + dataObj[key];
            }
        }
    }
    return url
}

/**
 * 本地文件服务失败
 */
function realFileError(callback, _self) {
    //是否允许外店登录
    let isOutLogin = sessionStorage.getItem("isOutLogin");
    if (isOutLogin == "true") {
        sessionStorage.setItem("visitType", "1");
        callback();
        _self.$notify({
            title: "警告",
            message: "检测到本地服务异常,已自动切换为外店身份登录!请及时处理后重新登录!",
            type: "warning"
        });
    } else {
        let Tablet = sessionStorage.getItem('Tablet');
        let ismac = localStorage.ismac != undefined && localStorage.ismac == "1" ? localStorage.ismac : '';
        _self.$router.push("/");
        sessionStorage.clear();
        localStorage.clear();
        if (Tablet) sessionStorage.setItem('Tablet',Tablet);
        localStorage.setItem("ismac", ismac);
        _self.$notify({
            title: "错误",
            message: "检测到本地服务异常,访问被拒绝!请及时处理后重新登录!",
            type: "error"
        });
    }
}

let isGetWxCOS = false;
let queueList = [];
let queueIngs = [];
const MAX_COUNT = 3;
/**
 * 封装上传 控制最大执行数
 */
function uploadFun(options, newCos) {
    /**
     * 任务队列还有在上传的文件，先不上传新的文件
     * isTipsOtherUpload 是否提示还有其他文件正在上传
     */
    if (
        queueIngs.length > 0 &&
        options.isTipsOtherUpload
    ) {
        if (process.env.NODE_ENV === 'development') console.log("queueList", queueList, 'queueIngs', queueIngs, queueIngs.length);
        Message.closeAll();
        Message({
            message: "还有文件上传中，稍后再试",
            type: 'warning'
        });
        return;
    }

    options.taskId = newGuid()
    queueList.push(options);

    if (process.env.NODE_ENV === 'development') console.log("queueList", queueList, 'queueIngs', queueIngs, queueIngs
        .length);
    if (queueIngs.length === 0) {
        taskHandle(newCos);
    }
}

/**
 * 任务中心 控制执行数量
 */
function taskHandle(newCos) {
    let freeCount = MAX_COUNT - queueIngs.length;

    for (let i = 0; i < freeCount; i++) {
        //从未执行队列加入
        if (queueList.length > 0) {
            //把当前任务加入执行队列并从未执行队列中移除
            startUpload(queueList[0], newCos);
            if (process.env.NODE_ENV === 'development') console.log("queueList", queueList, 'queueIngs', queueIngs,
                queueIngs.length);
            queueList.splice(0, 1);
        } else if (queueIngs.length > 0) {
            console.log("已无可处理任务");
        } else {
            console.log("已全部处理完成");
        }
    }
}

// 初始化上传实例
window.cos = new COS({
    FileParallelLimit: 4,
    ProgressInterval: 100,
    getAuthorization: (options, callback) => {
        Custom_Method.$axios({
            url: "NewWeddingApi/FileManage/CosMethod/CosHelp/GetUserFederationToken",
            method: "POST",
            success(res) {
                if (res && res.data.status) {
                    callback({
                        TmpSecretId: res.data.data.Credentials.TmpSecretId,
                        TmpSecretKey: res.data.data.Credentials.TmpSecretKey,
                        XCosSecurityToken: res.data.data.Credentials.Token,
                        ExpiredTime: res.data.data.ExpiredTime,
                    });
                }
            },
            error(err) {
                console.log(err);
            }
        });
    }
});


// 微信小程序端上传 的 存储桶
let bucket_wxChat = {
    bucket: "weddingbo-1256393015",
    region: "ap-chengdu"
}
/**
 * 上传走小程序的的配置 获取cos的情况
 */
function getWxChatCos() {
    // 初始化上传实例
    if (!isGetWxCOS) {
        window.wxCos = new COS({
            FileParallelLimit: 4,
            ProgressInterval: 100,
            getAuthorization: (options, callback) => {
                Axios({
                    method: 'GET',
                    url: 'https://host.weddingbo.com/xlapi/HostManage/HostUserManage/BaseUser/GetScrip',
                    data: {unused: 0},
                }).then(res => {
                    isGetWxCOS = true
                    try {
                        callback({
                            TmpSecretId: res.data.Credentials.TmpSecretId,
                            TmpSecretKey: res.data.Credentials.TmpSecretKey,
                            XCosSecurityToken: res.data.Credentials.Token,
                            ExpiredTime: res.data.ExpiredTime,
                        });
                    } catch (e) {
                        queueIngs = []
                    }
                }).catch(err => {
                    isGetWxCOS = false
                    queueIngs = []
                });
            }
        });
    }

    return window.wxCos;
}

/**
 * 下载文件
 * @param {*} newCos
 * @param {*} options 下载文件参数
 */
function downLoad(newCos, options) {
    let uploadConfig = {
        bucket: sessionStorage.getItem("Bucket_B"),
        region: sessionStorage.getItem("Region_B")
    }
    if (newCos) {
        cos = newCos;
    }
    cos.getObjectUrl({
        Bucket: uploadConfig.bucket,
        Region: uploadConfig.region,
        Key: options.key,
        Sign: true
    }, function(err, data) {
        if (err) return console.log(err);
        var downloadUrl = data.Url + (data.Url.indexOf('?') > -1 ? '&' : '?') + 'response-content-disposition=attachment'; // 补充强制下载的参数
        window.open(downloadUrl); // 这里是新窗口打开 url，如果需要在当前窗口打开，可以使用隐藏的 iframe 下载，或使用 a 标签 download 属性协助下载
    });
}

/**
 * 执行操作
 */
function startUpload(options, newCos) {
    //把当前任务加入执行队列
    queueIngs.push(options);
    console.log(`开始处理${options.taskId}`);
    let ysType = options.ysType ? options.ysType : 1;
    switch (ysType) {
        case 2:
            {
                newFileAndYsFile(newCos, options);
            };
            break;
        case 3:
            {
                newPutObject(newCos, options, true);
            };
            break;
        default:
            {
                //其他情况同默认情况 仅上传源文件
                newPutObject(newCos, options, false);
            }
    }
}
/**
 * 单个上传 (单个独立进度)
 * @param {*} options 上传文件对象
 * @param {*} isYsFile 是否压缩文件
 */
function newPutObject(newCos, options, isYsFile) {
    //判断是否是图片 仅图片压缩
    if (options.file.type.indexOf('image') < 0 || options.isNoYs) {
        newLRZUpload(newCos, options);
        return;
    }
    if (isYsFile) {
        // lrz(options.file, {
        //     width: 680,
        //     heidht: 440,
        //     quality: 0.8 //图片质量
        CompressorFile(options, {
            maxWidth: 680,
            // heidht: 440,
            quality: 0.8 //图片质量
        }).then(res => {
            // options.file = dataURLtoBlob(res.base64, options.file.name);
            options.file = res
            newLRZUpload(newCos, options);
        });
    } else {
        // lrz(options.file, {
        //     width: 1920,
        //     quality: 0.9 //图片质量
        CompressorFile(options, {
            width: 2500,
            quality: 0.9 //图片质量
        }).then(res => {
            // options.file = dataURLtoBlob(res.base64, options.file.name);
            options.file = res
            newLRZUpload(newCos, options);
        });
    }
}
/**
 * 上传压缩后的文件
 * @param {*} newCos 新的上传实例
 * @param {*} options 上传参数
 */
function newLRZUpload(newCos, options) {
    let cos = window.cos;

    let uploadConfig = {
        bucket: sessionStorage.getItem("Bucket_B"),
        region: sessionStorage.getItem("Region_B")
    }
    if (newCos) {
        cos = newCos;
    }
    //获取文件后缀名
    let file = options.file;

    let suffix = '.' + getSuffix(file.name);

    /**
     * 判断走小程序的上传配置
     */
    // const storeWxFile = getStoreWxFile();
    if (
        options.isWxChartUpload
        // &&
        // storeWxFile &&
        // storeWxFile.length > 0 &&
        // storeWxFile.includes(file.name)
    ) {
        cos = getWxChatCos();
        uploadConfig = bucket_wxChat;
    }

    let fileKey = ""; //上传文件名(唯一标识)
    let pathKey = options.pathKey ? options.pathKey : "Test/"; //上传路径
    //判断是否需要生成guid作为文件名 默认生成
    let isNewGuid = options.isNewGuid ? options.isNewGuid : true;
    //判断是否传递了field 若传递了 则使用fileID作为上传文件的文件名
    if (options.fileId) {
        fileKey = options.fileId;
    } else if (isNewGuid) {
        fileKey = newGuid();
    } else {
        fileKey = file.name;
    }
    //上传文件
    cos.putObject({
            Bucket: uploadConfig.bucket,
            Region: uploadConfig.region,
            Key: pathKey + fileKey + suffix,
            Body: file,
            onProgress: function(progressData) {
                let progress = Math.round(progressData.percent * 100);
                options.onProgress ? options.onProgress(progress) : () => {};
                const obj = {
                    ...progressData,
                    cFileSize: file.body && file.body.size ? file.body.size : 0,
                }
                options.onProgressHandler ? options.onProgressHandler(obj) : () => {};
                options.allProgress ? options.allProgress(progressData) : () => {};
            },
            onTaskReady: function(taskId) {
                //上传任务建立完成返回任务id
                console.log(taskId);
                options.onTaskReady ? options.onTaskReady(taskId) : () => {};
            }
        },
        function(err, data) {
            console.log(err || data);
            let upload = {};
            if (data) {
                upload.key = data.Location.split('.com/')[1];
                upload.fileSize = Math.round(file.size / 1024 * 100) / 100;
                console.log("file", upload);
                options.success ? options.success(upload) : () => {
                    console.log("上传成功")
                };
                //执行完成从执行队列移除该任务
                let ingIndex = queueIngs.findIndex((t) => t.taskId === options.taskId);
                queueIngs.splice(ingIndex, 1);
                // console.log(`${options.taskId}处理完成`);
                //返回任务中心处理
                taskHandle();
            } else {
                //执行完成从执行队列移除该任务
                let ingIndex = queueIngs.findIndex((t) => t.taskId === options.taskId);
                queueIngs.splice(ingIndex, 1);
                //返回失败状态
                options.error ? options.error() : () => {
                    console.log("上传失败");
                };
            }
        }
    );
}
/**
 * 上传源文件和压缩文件 可以取消
 * @param {*} options 上传文件参数对象
 */
function newFileAndYsFile(newCos, options) {
    let cos = window.cos;

    let uploadConfig = {
        bucket: sessionStorage.getItem("Bucket_B"),
        region: sessionStorage.getItem("Region_B")
    }
    if (newCos) {
        cos = newCos;
    }
    //获取文件后缀名
    let file = options.file;
    let suffix = '.' + getSuffix(file.name);

    /**
     * 判断走小程序的上传配置
     */
    // const storeWxFile = getStoreWxFile();
    if (
        options.isWxChartUpload
        // &&
        // storeWxFile &&
        // storeWxFile.length > 0 &&
        // storeWxFile.includes(file.name)
    ) {
        cos = getWxChatCos();
        uploadConfig = bucket_wxChat;
    }

    let fileKey = ""; //上传文件名(唯一标识)
    let pathKey = options.pathKey ? options.pathKey : "Test/"; //上传路径
    //判断是否需要生成guid作为文件名 默认生成
    let isNewGuid = options.isNewGuid ? options.isNewGuid : true;
    //判断是否传递了field 若传递了 则使用fileID作为上传文件的文件名
    if (options.fileId) {
        fileKey = options.fileId;
    } else if (isNewGuid) {
        fileKey = newGuid();
    } else {
        fileName = options.file.name;
    }
    if (options.thFile) {
        // lrz(options.thFile, {
        //     width: 816,
        //     heidht: 528,
        //     quality: 0.8 //图片质量(默认0.7)
        // }).then(res => {
        CompressorFile({file:options.thFile}, {
            maxWidth: 1500, // 原来的 816 改成了 1500
            quality: 0.8 //图片质量(默认0.7)
        }).then(res => {
            let fileList = [{
                key: pathKey + fileKey + suffix,
                isYsFile: false,
                body: options.file
            }, {
                key: pathKey + fileKey + '-ys.png',
                isYsFile: true,
                body:res
                // body: dataURLtoBlob(res.base64, options.file.name)
            }];
            let ysFileState = 0,
                fileState = 0; //文件上传状态 0：未上传/上传中 1：上传成功 2：上传失败
            let fileTaskId = "",
                ysTaskId = ""; ////文件上传任务ID
            let upload = {
                    key: "", //源文件key
                    fileSize: 0, //源文件大小(kb)
                    ysKey: "", //压缩文件key
                    ysFileSize: 0 //源文件大小(kb)
                }
                //上传文件
            fileList.forEach(file => {
                cos.putObject({
                        Bucket: uploadConfig.bucket,
                        Region: uploadConfig.region,
                        Key: file.key,
                        Body: file.body,
                        onProgress: function(progressData) {
                            let progress = Math.round(progressData.percent * 100);
                            if (!file.isYsFile) {
                                //返回非压缩文件进度
                                options.onProgress ? options.onProgress(progress) : () => {};
                                const obj = {
                                    ...progressData,
                                    cFileSize: file.body && file.body.size ? file.body.size : 0,
                                }
                                options.onProgressHandler ? options.onProgressHandler(obj) : () => {};
                                options.allProgress ? options.allProgress(progressData) : () => {};
                            }
                        },
                        onTaskReady: function(taskId) {
                            if (!file.isYsFile) {
                                fileTaskId = taskId;
                            } else {
                                ysTaskId = taskId;
                            }
                            //压缩文件和源文件 上传任务都建立完成后 返回任务id
                            if (fileTaskId && ysTaskId) {
                                console.log(fileTaskId, ysTaskId);
                                options.onTaskReady ? options.onTaskReady({
                                    fileTaskId: fileTaskId,
                                    ysTaskId: ysTaskId
                                }) : () => {};

                            }
                        }
                    },
                    function(err, data) {
                        console.log(err || data);
                        if (data) {
                            if (!file.isYsFile) {
                                upload.key = data.Location.split('.com/')[1];
                                upload.fileSize = Math.round(file.body.size / 1024 * 100) / 100;
                            } else {
                                upload.ysKey = data.Location.split('.com/')[1];
                                upload.ysFileSize = Math.round(file.body.size / 1024 * 100) / 100;
                            }
                            file.isYsFile ? ysFileState = 1 : fileState = 1;
                            //压缩文件及源文件都上传成功 返回成功状态
                            if (ysFileState == 1 && fileState == 1) {
                                console.log("file", upload);
                                options.success ? options.success(upload) : () => {
                                    console.log("上传成功")
                                };

                                //执行完成从执行队列移除该任务
                                let ingIndex = queueIngs.findIndex((t) => t.taskId === options.taskId);
                                queueIngs.splice(ingIndex, 1);
                                // console.log(`${options.taskId}处理完成`);
                                //返回任务中心处理
                                taskHandle();
                            }

                        } else {
                            file.isYsFile ? ysFileState = 2 : fileState = 2;
                            //压缩文件或源文件上传失败 返回失败状态
                            if (ysFileState == 2 || fileState == 2) {
                                //执行完成从执行队列移除该任务
                                let ingIndex = queueIngs.findIndex((t) => t.taskId === options.taskId);
                                queueIngs.splice(ingIndex, 1);
                                options.error ? options.error() : () => {
                                    console.log("上传失败");
                                };
                            }
                            //一个文件失败时 需要判断另一个文件状态
                            if (file.isYsFile && fileState == 1) {
                                //上传成功则删除
                                deleteMultipleObject([{
                                    Key: pathKey + fileKey + suffix
                                }]);
                            } else if (file.isYsFile && fileState == 0 && fileTaskId) {
                                //上传中则取消上传
                                cancelUpload(cos,fileTaskId, 'cancel',null,options);
                            } else if (!file.isYsFile && ysFileState == 1) {
                                //上传成功则删除
                                deleteMultipleObject([{
                                    Key: pathKey + fileKey + suffix
                                }]);
                            } else if (!file.isYsFile && ysFileState == 0 && ysTaskId) {
                                //上传中则取消上传
                                cancelUpload(cos,ysTaskId, 'cancel',null,options);
                            }
                        }
                    }
                );
            });
        });
    } else if (options.thFile === null) {
        let fileList = [{
            key: pathKey + fileKey + suffix,
            isYsFile: false,
            body: options.file
        }];
        let ysFileState = 0,
            fileState = 0; //文件上传状态 0：未上传/上传中 1：上传成功 2：上传失败
        let fileTaskId = "",
            ysTaskId = ""; ////文件上传任务ID
        let upload = {
                key: "", //源文件key
                fileSize: 0, //源文件大小(kb)
                ysKey: "", //压缩文件key
                ysFileSize: 0 //源文件大小(kb)
            }
            //上传文件
        fileList.forEach(file => {
            cos.putObject({
                    Bucket: uploadConfig.bucket,
                    Region: uploadConfig.region,
                    Key: file.key,
                    Body: file.body,
                    onProgress: function(progressData) {
                        let progress = Math.round(progressData.percent * 100);
                        options.onProgress ? options.onProgress(progress) : () => {};
                        const obj = {
                            ...progressData,
                            cFileSize: file.body && file.body.size ? file.body.size : 0,
                        }
                        options.onProgressHandler ? options.onProgressHandler(obj) : () => {};
                    },
                    onTaskReady: function(taskId) {
                        fileTaskId = taskId;
                        //上传任务建立完成后 返回任务id
                        if (fileTaskId) {
                            options.onTaskReady ? options.onTaskReady({
                                fileTaskId: fileTaskId
                            }) : () => {};
                        }
                    }
                },
                function(err, data) {
                    console.log(err || data);
                    if (data) {

                        upload.key = data.Location.split('.com/')[1];
                        upload.fileSize = Math.round(file.body.size / 1024 * 100) / 100;
                        fileState = 1;
                        //压缩文件及源文件都上传成功 返回成功状态
                        if (fileState == 1) {
                            console.log("file", upload);
                            options.success ? options.success(upload) : () => {
                                console.log("上传成功")
                            };
                            //执行完成从执行队列移除该任务
                            let ingIndex = queueIngs.findIndex((t) => t.taskId === options.taskId);
                            queueIngs.splice(ingIndex, 1);
                            // console.log(`${options.taskId}处理完成`);
                            //返回任务中心处理
                            taskHandle();
                        }
                    } else {
                        fileState = 2;
                        //压缩文件或源文件上传失败 返回失败状态
                        if (fileState == 2) {
                            //执行完成从执行队列移除该任务
                            let ingIndex = queueIngs.findIndex((t) => t.taskId === options.taskId);
                            queueIngs.splice(ingIndex, 1);
                            options.error ? options.error() : () => {
                                console.log("上传失败");
                            };
                        }
                    }
                }
            );
        });
    } else {
        // Promise.all([lrz(options.file, {
        //     width: 1920,
        //     quality: 0.8 //图片质量(默认0.7)
        // }), lrz(options.file, {
        //     width: 680,
        //     heidht: 440,
        //     quality: 0.8 //图片质量(默认0.7)
        // })]).then(ress => {
        Promise.all([CompressorFile(options, {
            maxWidth: 2500,
            quality: 0.9 //图片质量(默认0.7)
        }), CompressorFile(options, {
            maxWidth: 1500, // 原来的 680 改成了 1500
            quality: 0.8 //图片质量(默认0.7)
            })]).then(ress => {
            let fileList = [{
                key: pathKey + fileKey + suffix,
                isYsFile: false,
                body: ress[0]
                // body: dataURLtoBlob(ress[0].base64, options.file.name)
            }, {
                key: pathKey + fileKey + '-ys' + suffix,
                isYsFile: true,
                body: ress[1]
                // body: dataURLtoBlob(ress[1].base64, options.file.name)
            }];
            let ysFileState = 0,
                fileState = 0; //文件上传状态 0：未上传/上传中 1：上传成功 2：上传失败
            let fileTaskId = "",
                ysTaskId = ""; ////文件上传任务ID
            let upload = {
                    key: "", //源文件key
                    fileSize: 0, //源文件大小(kb)
                    ysKey: "", //压缩文件key
                    ysFileSize: 0 //源文件大小(kb)
                }
                //上传文件
            fileList.forEach(file => {
                cos.putObject({
                        Bucket: uploadConfig.bucket,
                        Region: uploadConfig.region,
                        Key: file.key,
                        Body: file.body,
                        onProgress: function(progressData) {
                            let progress = Math.round(progressData.percent * 100);
                            if (!file.isYsFile) {
                                //返回非压缩文件进度
                                options.onProgress ? options.onProgress(progress) : () => {};
                                const obj = {
                                    ...progressData,
                                    cFileSize: file.body && file.body.size ? file.body.size : 0,
                                }
                                options.onProgressHandler ? options.onProgressHandler(obj) : () => {};
                                options.allProgress ? options.allProgress(progressData) : () => {};
                            }
                        },
                        onTaskReady: function(taskId) {
                            if (!file.isYsFile) {
                                fileTaskId = taskId;
                            } else {
                                ysTaskId = taskId;
                            }
                            //压缩文件和源文件 上传任务都建立完成后 返回任务id
                            if (fileTaskId && ysTaskId) {
                                console.log(fileTaskId, ysTaskId);
                                options.onTaskReady ? options.onTaskReady({
                                    fileTaskId: fileTaskId,
                                    ysTaskId: ysTaskId
                                }) : () => {};

                            }
                        }
                    },
                    function(err, data) {
                        console.log(err || data);
                        if (data) {
                            if (!file.isYsFile) {
                                upload.key = data.Location.split('.com/')[1];
                                upload.fileSize = Math.round(file.body.size / 1024 * 100) / 100;
                            } else {
                                upload.ysKey = data.Location.split('.com/')[1];
                                upload.ysFileSize = Math.round(file.body.size / 1024 * 100) / 100;
                            }
                            file.isYsFile ? ysFileState = 1 : fileState = 1;
                            //压缩文件及源文件都上传成功 返回成功状态
                            if (ysFileState == 1 && fileState == 1) {
                                console.log("file", upload);
                                options.success ? options.success(upload) : () => {
                                    console.log("上传成功")
                                };
                                //执行完成从执行队列移除该任务
                                let ingIndex = queueIngs.findIndex((t) => t.taskId === options
                                    .taskId);
                                queueIngs.splice(ingIndex, 1);
                                // console.log(`${options.taskId}处理完成`);
                                //返回任务中心处理
                                taskHandle();
                            }
                        } else {
                            file.isYsFile ? ysFileState = 2 : fileState = 2;
                            //压缩文件或源文件上传失败 返回失败状态
                            if (ysFileState == 2 ||
                                fileState == 2) {
                                let ingIndex = queueIngs.findIndex((t) => t.taskId === options
                                    .taskId);
                                queueIngs.splice(ingIndex, 1);
                                options.error ? options.error() : () => {
                                    console.log("上传失败");
                                };
                            }
                            //一个文件失败时 需要判断另一个文件状态
                            if (file.isYsFile && fileState == 1) {
                                //上传成功则删除
                                deleteMultipleObject([{
                                    Key: pathKey + fileKey + suffix
                                }]);
                            } else if (file.isYsFile && fileState == 0 && fileTaskId) {
                                //上传中则取消上传
                                cancelUpload(cos,fileTaskId, 'cancel',null,options);
                            } else if (!file.isYsFile && ysFileState == 1) {
                                //上传成功则删除
                                deleteMultipleObject([{
                                    Key: pathKey + fileKey + suffix
                                }]);
                            } else if (!file.isYsFile && ysFileState == 0 && ysTaskId) {
                                //上传中则取消上传
                                cancelUpload(cos,ysTaskId, 'cancel',null,options);
                            }
                        }
                    }
                );
            });
        }).catch(err => {
            console.log("err", err)
        });
    }

}

/**
 * 批量上传 上传源文件和压缩文件 (统一进度 无法取消)
 * @param {*} options  上传文件参数对象
 */
function newUploadFiles(newCos, options) {
    let uploadConfig = {
        bucket: sessionStorage.getItem("Bucket_B"),
        region: sessionStorage.getItem("Region_B")
    }
    if (newCos) {
        cos = newCos;
    }
    //获取文件后缀名
    let file = options.file;
    let suffix = '.' + getSuffix(file.name);
    let fileKey = "";
    //判断是否需要生成guid作为文件名 默认生成
    let isNewGuid = options.isNewGuid ? options.isNewGuid : true;
    //判断是否传递了field 若传递了 则使用fileID作为上传文件的文件名
    if (options.fileId) {
        fileKey = options.fileId;
    } else if (isNewGuid) {
        fileKey = newGuid();
    } else {
        fileName = options.file.name;
    }
    //压缩文件
    // lrz(options.file, {
    //     width: 1920,
    //     quality: 0.8 //图片质量(默认0.7)
    // }).then(res => {
    CompressorFile(options, {
        maxWidth: 2500,
        quality: 0.9 //图片质量(默认0.7)
    }).then(res => {
        let fileList = [{
            Bucket: uploadConfig.bucket,
            Region: uploadConfig.region,
            Key: "Test/" + fileKey + suffix,
            Body: options.file
        }, {
            Bucket: uploadConfig.bucket,
            Region: uploadConfig.region,
            Key: "Test/" + fileKey + '-ys' + suffix,
            Body: res
            // Body: dataURLtoBlob(res.base64, 'ysFile')
        }];
        cos.uploadFiles({
                files: fileList,
                SliceSize: 1024 * 1024 * 1024, //若超过此大小 则使用分片上传
                onProgress: function(info) {
                    var percent = parseInt(info.percent * 10000) / 100;
                    var speed = parseInt((info.speed / 1024 / 1024) * 100) / 100;
                    options.onProgress ? options.onProgress(percent) : () => {};
                    console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
                },
                onFileFinish: function(err, data, options) {
                    console.log(options.Key + "上传" + (err ? "失败" : "完成"));
                },
                onTaskReady: function(taskId) {
                    options.onTaskReady ? options.onTaskReady(taskId) : () => {};
                }
            },
            function(err, data) {
                if (data) {
                    options.success ? options.success() : () => {
                        console.log("上传成功")
                    };
                } else {
                    options.error ? options.error() : () => {
                        console.log("上传失败");
                    };
                }
            }
        );
    });
}

/**
 * 处理批量上传的文件
 * @param {*} options
 */
function handleFiles(options) {
    let uploadConfig = {
            bucket: sessionStorage.getItem("Bucket_B"),
            region: sessionStorage.getItem("Region_B")
        }
        //获取文件后缀名
    let file = options.file;
    let suffix = '.' + getSuffix(file.name);
    let fileKey = "";
    let pathKey = options.pathKey ? options.pathKey : "Test/"; //上传路径
    //判断是否需要生成guid作为文件名 默认生成
    let isNewGuid = options.isNewGuid ? options.isNewGuid : true;
    //判断是否传递了field 若传递了 则使用fileID作为上传文件的文件名
    if (options.fileId) {
        fileKey = options.fileId;
    } else if (isNewGuid) {
        fileKey = newGuid();
    } else {
        fileName = options.file.name;
    }
    let lrzFile = null;
    return new Promise(resolve => {
        if (options.isLrz) {
            // Promise.all([lrz(options.file, {
            //     width: 1920,
            //     quality: 0.9 //图片质量(默认0.7)
            // }), lrz(options.file, {
            //     width: 680,
            //     heidht: 440,
            //     quality: 0.8 //图片质量(默认0.7)
            // })]).then(ress => {
            Promise.all([CompressorFile(options, {
                maxWidth: 2500,
                quality: 0.9 //图片质量(默认0.7)
            }), CompressorFile(options, {
                maxWidth: 680,
                quality: 0.8 //图片质量(默认0.7)
            })]).then(ress => {
                lrzFile = [{
                    Bucket: uploadConfig.bucket,
                    Region: uploadConfig.region,
                    Key: pathKey + fileKey + suffix,
                    coverType: options.coverType,
                    type: options.type,
                    fileKey: fileKey,
                    Body:ress[0]
                    // Body: dataURLtoBlob(ress[0].base64, options.file.name)
                }, {
                    Bucket: uploadConfig.bucket,
                    Region: uploadConfig.region,
                    Key: pathKey + fileKey + '-ys' + suffix,
                    coverType: "ImgCover",
                    fileKey: fileKey,
                    Body:ress[1]
                    // Body: dataURLtoBlob(ress[1].base64, options.file.name)
                }];
                resolve(lrzFile);
            })
        } else {
            if ((options.coverType === 'WorksFile' || options.coverType === 'WorksMusic') && (options.type === 0 || options.type === 5)) {
                lrzFile = [{
                    Bucket: uploadConfig.bucket,
                    Region: uploadConfig.region,
                    Key: pathKey + fileKey + suffix,
                    Body: options.file,
                    videoId: options.fileId,
                    type: options.type,
                    fileKey: fileKey,
                    coverType: options.coverType
                }];
                resolve(lrzFile);
            } else {
                //压缩文件
                // lrz(options.file, {
                //     width: 1920,
                //     quality: 0.9 //图片质量(默认0.7)
                // }).then(res => {
                CompressorFile(options, {
                    maxWidth: 2500,
                    quality: 0.9 //图片质量(默认0.7)
                }).then(res => {
                    lrzFile = [{
                        Bucket: uploadConfig.bucket,
                        Region: uploadConfig.region,
                        Key: pathKey + fileKey + suffix,
                        coverType: options.coverType,
                        fileKey: fileKey,
                        Body: res
                        // Body: dataURLtoBlob(res.base64, options.file.name)
                    }];
                    resolve(lrzFile);
                });

            }

        }
    })

}
/**
 * 批量上传 上传源文件和压缩文件 (统一进度 无法取消)
 * @param {*} optionsList  上传文件参数对象集合(请携带是否需要压缩的字段 isLrz:true(压缩) false(不压缩) 默认不压缩)
 */
async function newUploadFileList(newCos, optionsList) {
    if (newCos) {
        cos = newCos;
    }
    let fileList = [];
    for (let i = 0; i < optionsList.fileList.length; i++) {
        let options = optionsList.fileList[i];
        let res = await handleFiles(options);
        console.log("res", res);
        fileList = [...fileList, ...res];
        console.log("fileList", fileList);
        if (i === optionsList.fileList.length - 1) {
            batchUpload(fileList, optionsList);
        }
    }

}

/**
 *  批量上传
 */
function batchUpload(fileList, optionsList) {
    cos.uploadFiles({
            files: fileList,
            SliceSize: 1024 * 1024, //若超过此大小 则使用分片上传
            onProgress: function(info) {
                var percent = parseInt(info.percent * 10000) / 100;
                var speed = parseInt((info.speed / 1024 / 1024) * 100) / 100;
                optionsList.onProgress ? optionsList.onProgress("进度：" + percent + "%; 速度：" + speed + "Mb/s;") : () => {};
                console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
            },
            onFileFinish: function(err, data, options) {
                let file = fileList.find(f => f.Key === options.Key);
                console.log("err", err);
                if (file) {
                    file.uploadState = err ? 0 : 1;
                }
                console.log(options.Key + "上传" + (err ? "失败" : "完成"));
            },
            onTaskReady: function(taskId) {
                optionsList.onTaskReady ? optionsList.onTaskReady(taskId) : () => {};
            }
        },
        function(err, data) {
            if (data) {
                console.log("fileList", fileList);
                optionsList.success ? optionsList.success(fileList) : () => {
                    console.log("上传成功")
                };
            } else {
                optionsList.error ? optionsList.error() : () => {
                    console.log("上传失败");
                };
            }
        }
    );
}

/**
 * 取消上传/重新上传(仅单个上传任务适用 批量上传无法适用)
 * @param {*} newCos COS对象
 * @param {*} taskId 上传任务id
 * @param {*} type 操作类型 'retry'重新上传 'cancel'取消上传
 * @param {*} callback 回调函数
 * @param {*} options 如果cos走的是小程序的cos ，必传 option.isWxChartUpload = true; 小程序端上传
 */
function cancelUpload(newCos, taskId, type, callback,options) {
    let cos = window.cos;

    //判断是否是多个任务
    let fileTaskId, ysTaskId;
    if (taskId && taskId.fileTaskId) {
        fileTaskId = taskId.fileTaskId;
        ysTaskId = taskId.ysTaskId;
    } else fileTaskId = taskId;
    if (newCos) cos = newCos;
    switch (type) {
        case "cancel":
            // cos.cancelTask不存在则使用旧的COS对象
            if (!cos.cancelTask) cos = window.lodCos;

            /**
             * 判断走小程序的上传配置
             */
            if (
                options &&
                options.isWxChartUpload
            ) {
                cos = getWxChatCos();
            }

            if (fileTaskId) cos.cancelTask(fileTaskId);
            if (ysTaskId) cos.cancelTask(ysTaskId);
            callback ? callback() : () => {};
            break; //取消上传
        case "retry":
            callback ? callback() : () => {};
            // cos.restartTask不存在则使用旧的COS对象
            if (!cos.restartTask) cos = window.lodCos;
            /**
             * 判断走小程序的上传配置
             */
            if (
                options &&
                options.isWxChartUpload
            ) {
                cos = getWxChatCos();
            }

            if (fileTaskId) cos.restartTask(fileTaskId);
            if (ysTaskId) cos.restartTask(ysTaskId);
            break; //重新上传
        default:
            console.log("错误操作");
    }
}

/**
 * 删除多个对象
 * @param {*} fileKeyList 删除对象key集合
 */
function deleteMultipleObject(newCos, fileKeyList, callback) {
    callback ? callback() : "";

    // let uploadConfig = {
    //     bucket: sessionStorage.getItem("Bucket_B"),
    //     region: sessionStorage.getItem("Region_B")
    // }
    // if (fileKeyList.length == 0) {
    //     return;
    // }
    // if (newCos) {
    //     cos = newCos;
    // }
    // cos.deleteMultipleObject({
    //     Bucket: uploadConfig.bucket,
    //     Region: uploadConfig.region,
    //     Objects: fileKeyList
    // }, function(err, data) {
    //     console.log(err || data);
    //     callback ? callback() : "";
    // });
}

/**
 * base64 转 二进制文件
 * @param {*} dataurl base64数据
 * @param {*} name  文件名称
 */
function dataURLtoBlob(dataurl, name) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    var blob = new Blob([u8arr], {
        type: mime
    });
    blob.name = name;
    return blob;
}

/**
 *  前台生成Guid
 */
function newGuid() {
    var guid = "";
    for (var i = 1; i <= 32; i++) {
        var n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
        if (i == 8 || i == 12 || i == 16 || i == 20) guid += "-";
    }
    return guid;
}

/**
 * 获取文件后缀名
 * @param {} filename 文件名称 file.name
 */
function getSuffix(filename) {
    return filename.substring(filename.lastIndexOf(".") + 1, filename.length);
}

/**
 * 对象克隆
 * @param {*} obj
 */
function clone(obj) {
    var o;
    if (typeof obj == "object") {
        if (obj === null) {
            o = null;
        } else {
            if (obj instanceof Array) {
                o = [];
                for (var i = 0, len = obj.length; i < len; i++) {
                    o.push(clone(obj[i]));
                }
            } else {
                if (obj instanceof Date) {
                    o = obj;
                } else {
                    o = {};
                    for (var j in obj) {
                        o[j] = clone(obj[j]);
                    }
                }
            }
        }
    } else {
        o = obj;
    }
    return o;
}

/**
 * 压缩图片文件
 * @param {*} file 新的上传实例
 */
function CompressorFile(data, qualityObj) {
    return new Promise((reslove, reject) => {
        new Compressor(data.file, {
            ...qualityObj,
            success(result) {
                reslove(result)
            },
            error(err) {
                reject()
            }
        })

    })
}

export default Custom_Method
