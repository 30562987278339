/*
 * @Author: tangshuo
 * @Date: 2021-01-19 11:45:18
 * @LastEditors: tangshuo
 * @LastEditTime: 2021-10-09 16:18:39
 * @Remarks:
 */

/**
 * 店基础信息及操作
 */
import config from "@/assets/js/config/config";
import { httpServe } from '@/assets/js/utils/request';

/**
 * 获取当前店铺酒店列表
 */
export function getBranchHotelList() {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/HomeShow/HomeShow/GetBranchHotelList`,
        method: 'POST',
        data: {
            PageIndex: 1,
            PageSize: 10000
        }
    });
}

/**
 * 获取当前用户基础信息
 */
export function GetUserInfoDetailNew(val) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/UserInfoMnage/UserInfo/UserInfo/GetUserInfoDetailNew`,
        method: 'POST',
        data: {
            UserId: val,
        }
    });
}

/**
 * 获取当前店铺基础信息
 */
 export function getBranchBaseInfo() {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/ShoppingManage/BranchGroupManage/BranchGroup/GetBranchBaseInfo`,
        method: 'POST',
    });
}

/**
 * 修改当前店铺基础信息
 */
 export function updateBranchLogoInfo(data) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/ShoppingManage/BranchGroupManage/BranchGroup/UpdateBranchLogoInfo`,
        method: 'POST',
        data: data
    });
}


/**
 * 修改用户头像 姓名
 */
export function getUpdateUserBasicPhone(data) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/SetManage/PersonalCenter/PersonalCenter/UpdateUserBasicPhone`,
        method: 'POST',
        data: data
    });
}


/**
 * 获取首页店铺设置信息
 */
 export function getPageInfoByBranchId(data) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/FileManage2.0/FileNew/GetPageInfoByBranchId`,
        method: 'POST',
        data: data
    });
}

/**
 * 设置或者修改首页配置
 */
 export function upBranchBanner(data) {
    return httpServe({
        url: `${config.baseApi}NewWeddingApi/FileManage/FileManage2.0/FileNew/UpBranchBanner`,
        method: 'POST',
        data: data
    });
}

/**
 * 获取欢迎页信息
 */
export function GetWelcomeInfo(data) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostUserManage/HostUser/GetWelcomeInfo`,
        method: 'GET',
        data: data,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
    });
}

/**
 * 获取主持人录音
 */
export function GetBanners(data) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostUserManage/HostUser/GetBanners`,
        method: 'GET',
        data: data,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
    });
}

/**
 * 删除首页展示图
 */
export function DelBanner(data) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostUserManage/HostUser/DelBanner`,
        method: 'GET',
        data: data,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
    });
}

/**
 * 上传首页展示图
 */
export function InsBanner(data) {
    return httpServe({
        url: `${config.hostApi}xlapi/HostManage/HostUserManage/HostUser/InsBanner`,
        method: 'POST',
        data: data,
        authorizationStr: sessionStorage.getItem("hostAuthStr"),
    });
}
