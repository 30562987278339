<!--
 * @Author: tangshuo
 * @Date: 2021-11-02 17:23:29
 * @LastEditors: tangshuo
 * @LastEditTime: 2022-01-11 18:55:49
 * @Remarks: 屏保设置
-->
<template>
  <ConDig
    title="门户页(休息)"
    width="480px"
    :isShowDig="isShowSetFull"
    :loading="isLodding"
    :appendToBody="true"
    @open="initData"
    @closed="$emit('closeFull')"
    @closeDig="$emit('closeFull')"
    @cancelClick="$emit('closeFull')"
    @confirmClick="setScreenProtectionInfo"
  >
    <main slot="dialogMain" class="full-pb-main">
      <div class="full-pb-content">
        <p class="top-p">请设置登录后默认是否进入门户页，以及门户页内容管理</p>
        <div class="pb-set-item">
          <span class="item-title">登录后默认进入门户页</span>
          <el-switch v-model="isOpen"></el-switch>
        </div>
        <div class="pb-set-item" v-if="false">
          <span class="item-title">规定时间内无操作进入屏保模式</span>
          <el-select v-model="timeOut">
            <el-option label="30S" value="1"></el-option>
            <el-option label="1分钟" value="2"></el-option>
            <el-option label="3分钟" value="3"></el-option>
            <el-option label="15分钟" value="4"></el-option>
            <el-option label="30分钟" value="5"></el-option>
            <el-option label="1小时" value="6"></el-option>
          </el-select>
        </div>
        <div class="pb-set-item">
          <span class="item-title">轮播效果</span>
          <el-select class="con-input" v-model="aniType">
            <el-option label="渐隐渐现" value="0"></el-option>
            <el-option label="左右轮播" value="1"></el-option>
            <el-option label="上下轮播" value="2"></el-option>
            <!-- <el-option label="翻转滚动" value="3"></el-option> -->
            <!-- <el-option label="旋转下坠" value="5"></el-option> -->
            <el-option label="随机" value="3"></el-option>
          </el-select>
        </div>
        <div class="img-item-box">
          <div class="img-title-box">
            <span class="title-item">展示内容</span>
            <span class="title-item1"
              >（最多20个文件，单个限制50M，可拖动排序）</span
            >
          </div>
          <draggable
            :forceFallback='true'
            v-model="imgList"
            ref="draggableBox"
            :options="{
              animation: 500,
              scrollSensitivity: 50,
              forceFallback: true,
              handle: '.el-image-dr',
            }"
            draggable=".item"
            class="imgbox-box"
          >
            <div class="img-box add-box" slot="header" @click="changeIcon">
              <i class="add-icon el-icon-plus" />
            </div>
<!--            @click="setLoginCoverBg(item, index)"-->
            <div
              class="img-box item"
              v-for="(item, index) of imgList"
              :key="index"
            >
              <img
                class="back-img"
                v-if="item.fileThSrc"
                :src="item.fileThSrc ? item.fileThSrc : defaultUrl"
              />
              <div class="video-mask el-image-dr"></div>
              <i
                class="del-icon el-icon-delete"
                @click.stop="removeImg(index)"
              ></i>
              <img
                class="player-icon"
                v-if="item.FileType == 2"
                src="images/home/icon_player1.png"
              />
              <div
                class="progress-mask"
                v-if="
                  item.uploadState == 3 ||
                  item.uploadState == 4 ||
                  item.uploadState == 5
                "
              >
                <div class="upload-error">
                  <span v-show="item.uploadState == 3" class="upload-tip"
                    >上传失败</span
                  >
                  <span
                    v-show="item.uploadState == 1 || item.uploadState == 4"
                    class="upload-tip"
                    >等待上传</span
                  >
                  <span v-show="item.uploadState == 5" class="upload-tip"
                    >上传中</span
                  >
                  <el-progress
                    v-if="item.uploadState == 5"
                    class="file-progress"
                    :show-text="false"
                    color="#33FFBB"
                    :percentage="item.progress"
                  ></el-progress>
                  <div class="btn-box">
                    <button
                      class="cancel-btn"
                      @click="cancelUpload(item, 'cancel', index)"
                    >
                      取消
                    </button>
                    <button
                      class="retry-btn"
                      v-if="item.uploadState == 3"
                      @click="cancelUpload(item, 'retry', index)"
                    >
                      重试
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </draggable>
        </div>
      </div>
      <input
        type="file"
        id="loginfile1"
        ref="loginfile1"
        multiple
        accept="image/png, image/jpeg, image/gif,video/mp4, video/x-m4v, video/quicktime"
        style="opacity: 0; width: 0; height: 0; position: absolute"
        @change="appendPicture"
      />
      <video
        id="upvideo"
        :src="videoSrc"
        style="opacity: 0; position: fixed; top: -99999px"
        crossorigin="anonymous"
        @loadeddata="setVideoCover"
        @error="videoLodeError"
      ></video>
      <ConTip
        type="warn"
        :isShowTip="showTipDig"
        cancelText="取消"
        tipText="存在未上传完成的图片,确认继续保存?"
        lessText="未上传的图片将不会保存"
        @confirmClick="setScreenProtectionInfo()"
        @closeTipDig="showTipDig = false"
        @cancelClick="showTipDig = false"
      ></ConTip>
    </main>
  </ConDig>
</template>

<script>
import draggable from "vuedraggable";
import {judgeImage} from '@/assets/js/utils/imgFormat';

// import TipDig from "@/components/caseReveal/tipDig";
export default {
  data() {
    return {
      videoSrc: "",
      showTipDig: false, //是否显示提示弹窗
      isOpen: false, //屏保是否开启
      timeOut: "1", //屏保间隔时间
      aniType: "1", //屏保动画类型
      pbId: "", //记录ID
      isLoading: false,
      isLodding: false,
      cosIp: sessionStorage.getItem("BranchCosCdnUrl"),
      acceptImage: [
        {
          type: "image/png",
          typeStr: ".png",
        },
        {
          type: "image/jpeg",
          typeStr: ".jpg|.jpeg",
        },
        {
          type: "image/gif",
          typeStr: ".gif",
        },
        {
          type: "video/mp4",
          typeStr: ".mp4",
        },
        {
          type: "video/x-m4v",
          typeStr: ".m4v",
        },
        {
          type: "video/quicktime",
          typeStr: ".mov",
        },
      ], //图片限制格式列表
      apiData: {}, //传输数据
      imgList: [],
    };
  },
  props: {
    isShowSetFull: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    draggable,
  },
  created() {},
  mounted() {},
  methods: {
    initData() {
      this.getScreenProtectionInfo();
    },
    /**
     * 获取视频第一帧作为封面
     */
    setVideoCover() {
      let video;
      let scale = 0.8;
      video = $("#upvideo").get(0);
      let canvas = document.createElement("canvas");
      let _this = this;
      canvas.width = video.videoWidth * scale;
      canvas.height = video.videoHeight * scale;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((imgfile) => {
        let url = "";
        let file = _this.imgList.find(
          (f) => f.fileSrc === _this.videoSrc.split("#")[0]
        );
        if (imgfile) {
          if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(imgfile);
          } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(imgfile);
          } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(imgfile);
          }
          file.fileThSrc = url;
          this.$forceUpdate();
          file.coverFile = imgfile;
          file.coverFile.name = "VideoCover.png";
        } else {
          file.coverFile = null;
        }
        file.isCutOut = true; //是否已经尝试截取过封面(成功失败都算)
        let videoList = _this.imgList.filter(
          (w) =>
            w.FileType === 2 &&
            !w.coverFile &&
            !w.OtherPath &&
            !w.isCutOut &&
            !w.ID
        );
        if (videoList.length > 0) {
          let issafariBrowser = !/Chrome/.test(navigator.userAgent);
          let videoSrc = videoList[0].fileSrc + "#t=3";
          if (issafariBrowser) {
            videoSrc = videoList[0].fileSrc;
          }
          _this.videoSrc = videoSrc;
        } else {
          _this.upLoadFile();
        }
      });
    },
    /**
     * 视频加载出错
     */
    videoLodeError() {
      let file = this.imgList.find(
        (f) => f.fileSrc === this.videoSrc.split("#")[0]
      );
      if (!file) {
        return;
      }
      file.coverFile = null;
      file.isCutOut = true;
      let videoList = this.imgList.filter(
        (w) =>
          w.FileType === 2 &&
          !w.coverFile &&
          !w.OtherPath &&
          !w.isCutOut &&
          !w.ID
      );

      if (videoList.length > 0) {
        let issafariBrowser = !/Chrome/.test(navigator.userAgent);
        let videoSrc = videoList[0].fileSrc + "#t=3";
        if (issafariBrowser) {
          videoSrc = videoList[0].fileSrc;
        }
        this.videoSrc = videoSrc;
      } else {
        this.upLoadFile();
      }
    },
    /**
     * 模拟点击 打开文件选择框
     */
    changeIcon() {
      if (this.imgList.length >= 20) {
        this.$Message("最多上传20个");
        return;
      }
      $("#loginfile1").val(""); //赋值为'',防止选择同一文件不触发change事件
      $("#loginfile1").trigger("click");
    },
    /**
     * 选择文件后
     */
    async appendPicture() {
      var imgNum = document.getElementById("loginfile1").files.length; //文件数量
      if (imgNum + this.imgList.length > 20) {
        this.$Message("最多上传20个,多余文件已忽略");
        imgNum = 20 - this.imgList.length;
      }
      for (let i = 0; i < imgNum; i++) {
        let imgNews = document.getElementById("loginfile1").files[i];
        let acceptList = [...this.acceptImage];
        let fileType = imgNews.type;
        if (
          !acceptList.find((f) => f.type === fileType) ||
          imgNews.size > 50 * 1024 * 1024
        ) {
          this.$Message(
            `仅支持以下格式:${acceptList
              .map((a) => a.typeStr)
              .join("|")},大小50M以内,不符合文件已过滤`
          );
          i = imgNum;
        }
      }
      let addFileList = [];
      for (let i = 0; i < imgNum; i++) {
        let imgNews = document.getElementById("loginfile1").files[i];
        let acceptList = [...this.acceptImage];
        let fileType = imgNews.type;
        if (
          acceptList.find((f) => f.type === fileType) &&
          imgNews.size <= 50 * 1024 * 1024
        ) {
          let isImg = await judgeImage(imgNews);
          if (isImg) {
            let fileId = this.$newGuid();
            let fileItem = {
              file: imgNews,
              fileSrc: this.getObjectURL(imgNews),
              fileThSrc:
                  fileType.indexOf("image") != -1 ? this.getObjectURL(imgNews) : "",
              isEdit: false,
              uploadState: 1, //上传状态 1：未上传 2：已上传 3：上传失败 4：待上传(已加入队列) 5：正在上传
              FileType: fileType.indexOf("image") != -1 ? 1 : 2,
              fileId: fileId,
            };
            addFileList.push(fileItem);
          }
        }
      }
      if (imgNum !== addFileList.length) {
        this.$message({
          message: '已过滤格式错误的图片',
          type: 'warning',
          duration: 1500,
          offset: 80,
        });
      }
      this.imgList = [...addFileList, ...this.imgList];
      let videoList = this.imgList.filter(
        (w) => w.FileType === 2 && !w.isCutOut && !w.ID
      );
      if (videoList.length > 0) {
        let issafariBrowser = !/Chrome/.test(navigator.userAgent);
        let videoSrc = videoList[0].fileSrc + "#t=3";
        if (issafariBrowser) {
          videoSrc = videoList[0].fileSrc;
        }
        this.videoSrc = videoSrc;
      } else {
        this.upLoadFile();
      }
    },
    upLoadFile() {
      let self = this;
      this.imgList
        .filter((f) => f.uploadState == 1)
        .forEach((file) => {
          this.$newUploadFile({
            file: file.file,
            fileId: "",
            pathKey: `${sessionStorage.getItem("BranchId")}/FullScreenIMG/`,
            thFile: file.FileType === 2 ? file.coverFile : "", //压缩文件(仅非图片文件有效)
            ysType: 2,
            onProgress(progress) {
              //根据文件id查找相应文件更改其上传进度
              file.uploadState = 5; //更改状态为正在上传
              file.progress = progress;
              self.$forceUpdate();
            },
            onTaskReady(taskId) {
              console.log("准备就绪,开始上传 ：", taskId);
              if (file) {
                file.uploadState = 4; //更改状态为待上传
                file.taskId = taskId;
                self.$forceUpdate();
              }
            },
            success(res) {
              // console.log("上传成功", res);
              let path = res.key;
              let ysPath = res.ysKey;
              file.uploadState = 2; //更改状态为已上传
              //反写数据
              file.fileSrc = path ? self.cosIp + path : "";
              file.fileThSrc = ysPath ? self.cosIp + ysPath : "";

              file.Path = path ? self.cosIp + path : "";
              file.OtherPath = ysPath ? self.cosIp + ysPath : "";
              self.$forceUpdate();
            },
            error() {},
          });
        });
    },
    /**
     * 取消上传/重新上传(仅单个上传任务适用 批量上传无法适用)
     * @param {*} file 上传文件对象
     * @param {*} type 操作类型 'retry'重新上传 'cancel'取消上传
     */
    cancelUpload(file, type, index = -1) {
      this.$cancelUpload(file.taskId, type, () => {
        //取消上传
        if (type == "cancel" && index != -1) {
          //移除文件
          this.imgList.splice(index, 1);
        } else {
          //重新上传 不做处理
        }
      });
    },
    /**
     * 移除文件
     */
    removeImg(index) {
      this.imgList.splice(index, 1);
    },
    /**
     * 本地文件转为可播放Url
     */
    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    /**
     * 获取屏保图片
     */
    getScreenProtectionInfo() {
      this.isLoading = true;
      this.$axios({
        url: "NewWeddingApi/FileManage/FileManage2.0/FileNew/GetBranchScreenProtectionInfo",
        method: "POST",
        success(res) {
          this.isLoading = false;
          if (res && res.data.status) {
            let fileList = res.data.data.DataList || [];
            fileList.forEach((f) => {
              f.fileSrc = f.Path;
              f.fileThSrc = f.OtherPath;
            });

            this.imgList = fileList;
            this.isOpen = res.data.data.IsUsed || false;
            this.timeType = res.data.data.TimeType || "1";
            this.aniType = res.data.data.PollEffectType || "1";
            this.pbId = res.data.data.Id || "";
            this.$store.state.homeData = res.data.data;
          } else {
            this.$Error(res.data.msg ? res.data.msg : "获取失败");
          }
        },
        error(err) {
          this.$Error("获取失败");
          this.isLoading = false;
          console.log(err);
        },
      });
    },
    /**
     * 设置屏保图片
     */
    setScreenProtectionInfo(isForce = false) {
      this.isLoading = true;
      let datalist = [];
      if (
        !isForce &&
        this.imgList.find(
          (img) =>
            img.uploadState === 1 ||
            img.uploadState === 4 ||
            img.uploadState === 5
        )
      ) {
        this.isLoading = false;
        this.showTipDig = true;
        return;
      }
      //筛选正在上传的图片
      let imgIngs = this.imgList.filter(
        (m) => m.uploadState === 4 || m.uploadState === 5
      );
      imgIngs.forEach((m) => {
        this.cancelUpload(m, "cancel");
      });
      this.imgList = this.imgList.filter(
        (im) => !im.uploadState || im.uploadState === 2
      );
      this.imgList.forEach((img) => {
        datalist.push({
          Path: img.Path,
          OtherPath: img.OtherPath,
          FileType: img.FileType,
        });
      });
      this.$axios({
        url: "NewWeddingApi/FileManage/FileManage2.0/FileNew/SetBranchScreenProtectionInfo",
        method: "POST",
        data: {
          Id: this.pbId,
          IsUsed: this.isOpen,
          TimeType: this.timeType,
          PollEffectType: this.aniType,
          DataList: datalist,
        },
        success(res) {
          this.isLoading = false;
          if (res && res.data.status) {
            this.$Success("操作成功");
            this.getScreenProtectionInfo();
            this.$emit('closeFull');
          } else {
            this.$Error(res.data.msg ? res.data.msg : "操作成功");
          }
        },
        error(err) {
          this.$Error("操作成功");
          this.isLoading = false;
          console.log(err);
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.full-pb-main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px 24px 0;
  height: 500px;
  overflow: auto;
  .full-pb-content {
    width: 100%;
    height: calc(100%);
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .top-p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eeeeee;
    @include themify($themes) {
      border-bottom: 1px solid themed("hover-bg");
      // color: themed("search-item-color");
    }
  }
  .pb-set-item {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
    @include themify($themes) {
      border-bottom: 1px solid themed("hover-bg");
    }
    .item-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      @include themify($themes) {
        color: themed("operate-color");
      }
    }
    .el-select {
      width: 110px;
      min-width: 110px;
      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        border-radius: 0;
      }
      ::v-deep .el-input__icon {
        line-height: 32px;
      }
    }
    ::v-deep .el-switch.is-checked .el-switch__core {
      @include themify($themes) {
        border-color: themed("font-color-active");
        background-color: themed("font-color-active");
      }
    }
  }
  .img-item-box {
    .img-title-box {
      padding-top: 16px;
      .title-item {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        @include themify($themes) {
          color: themed("operate-color");
        }
      }
      .title-item1 {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        @include themify($themes) {
          color: themed("operate-color");
        }
      }
    }
    .imgbox-box {
      display: flex;
      flex-wrap: wrap;
      .img-box {
        width: 134px;
        height: 76px;
        display: inline-block;
        position: relative;
        overflow: hidden;
        border-radius: 2px;
        margin: 6px 6px 0 0;
        background: #f5f5f5;
        @include themify($themes) {
          background: themed("search-his-bg1");
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover {
          .del-icon {
            opacity: 1;
          }
        }
        .back-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }
        .video-mask {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          background: rgba($color: #000000, $alpha: 0.2);
          user-select: none;
          cursor: pointer;
        }
        .player-icon {
          width: 16px;
          height: 16px;
          object-fit: cover;
          position: absolute;
          bottom: 9px;
          left: 9px;
          z-index: 1;
        }
        .del-icon {
          position: absolute;
          bottom: 9px;
          right: 8px;
          font-size: 15px;
          color: #ffffff;
          cursor: pointer;
          opacity: 0;
          z-index: 1;
        }
        .progress-mask {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 5px;
          top: 0;
          left: 0;
          z-index: 1;
          .btn-box {
            display: flex;
            justify-content: center;
            margin-top: 10px;
          }
          .cancel-btn {
            width: 48px;
            height: 24px;
            border-radius: 12px;
            border: 1px solid rgba(255, 255, 255, 1);
            color: #ffffff;
            font-size: 12px;
            background: transparent;
            outline: none;
            cursor: pointer;
          }
          .retry-btn {
            width: 48px;
            height: 24px;
            background: rgba(255, 255, 255, 1);
            border-radius: 12px;
            border: #ffffff;
            color: #333333;
            font-size: 12px;
            background: #ffffff;
            cursor: pointer;
            margin-left: 10px;
            outline: none;
          }
          .upload-tip {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            // margin-bottom: 10px;
          }
          .file-progress {
            width: 110px;
          }
        }
      }
      .add-box {
        border: 1px dashed #cccccc;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
